// @mui
import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';
import Link from 'next/link';
// @mui
import { Box, Card, Grid, Typography } from '@mui/material';
// components
// sections
import Image from 'next/image';
import { MotionViewport, varFade } from '../../components/animate';
import { PATH_THIGPLX } from '../../routes/paths';

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  paddingBottom: 30,
  '&:hover': {
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  },
  cursor: 'pointer'
}));

const Header = styled(Box)(({ theme }) => ({
  width: 70,
  borderRadius: 25,
  padding: 5,
  ...theme.typography.body1,
  color: "#fff"
}));

// ----------------------------------------------------------------------
export default function HomeMenu() {
  return (<>
    <Grid container spacing={3} component={MotionViewport}>
      <Grid item xs={12} sm={4}>
        <m.div variants={varFade().inDown}>
          <Link href={PATH_THIGPLX.car} style={{ textDecoration: 'none' }} passHref>
            <Item>
              <Header sx={{ backgroundColor: '#19b389' }}> 600 </Header>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <Image alt={"Ôn thi GPLX ô tô"} src={"/icons/car.png"} height={50} width={50} />
              </Box>
              <Typography variant='subtitle1'>Ôn Thi GPLX ô tô</Typography>
            </Item>
          </Link>
        </m.div>
      </Grid>
      <Grid item xs={12} sm={4} >
        <m.div variants={varFade().inRight}>
          <Link href={PATH_THIGPLX.bike} style={{ textDecoration: 'none' }} passHref>
            <Item>
              <Header sx={{ backgroundColor: '#3e67c3' }}>200</Header>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <Image alt={"Ôn thi GPLX xe máy"} src={"/icons/motorcycle.png"} height={50} width={50} />
              </Box>
              <Typography variant='subtitle1'>Ôn Thi GPLX xe máy</Typography>
            </Item>
          </Link>
        </m.div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <m.div variants={varFade().inLeft}>
          <Link href={PATH_THIGPLX.situations} style={{ textDecoration: 'none' }} passHref>
            <Item>
              <Header sx={{ backgroundColor: '#ed7a57' }}>120</Header>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <Image alt={"Ôn thi Mô phỏng"} src={"/icons/simulator.png"} height={50} width={50} />
              </Box>
              <Typography variant='subtitle1'>Ôn thi Mô phỏng</Typography>
            </Item>
          </Link>
        </m.div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <m.div variants={varFade().inLeft}>
          <Link href={PATH_THIGPLX.practicalExam} style={{ textDecoration: 'none' }} passHref>
            <Item>
              <Header sx={{ backgroundColor: '#4f6183' }}>8</Header>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <Image alt={"Bài thi sa hình"} src={"/illustrations/driving-school.png"} height={50} width={50} />
              </Box>
              <Typography variant='subtitle1'>Bài thi sa hình</Typography>
            </Item>
          </Link>
        </m.div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <m.div variants={varFade().inLeft}>
          <Link href={PATH_THIGPLX.street} style={{ textDecoration: 'none' }} passHref>
            <Item>
              <Header sx={{ backgroundColor: '#c7906f' }}>1</Header>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <Image alt={"Bài thi đường trường"} src={"/illustrations/road.png"} height={50} width={50} />
              </Box>
              <Typography variant='subtitle1'>Bài thi đường trường</Typography>
            </Item>
          </Link>
        </m.div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <m.div variants={varFade().inLeft}>
          <Link href={PATH_THIGPLX.blog} style={{ textDecoration: 'none' }} passHref>
            <Item>
              <Header sx={{ backgroundColor: '#46a3ab' }}>*</Header>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <Image alt={"Tin tức giao thông"} src={"/illustrations/police.png"} height={50} width={50} />
              </Box>
              <Typography variant='subtitle1'>Tin tức giao thông</Typography>
            </Item>
          </Link>
        </m.div>
      </Grid>
    </Grid>
  </>);
}
